.home {
    /* padding-top: 10px; */
    /* border: black 1px solid; */
}

.home section{
    height: 100%;
    background-color: #eff1f2;
}

.leftnav {
    padding-top: 0px;
    padding-left: 0px;
    background-color: #2e3338;
    height: calc(100vh - 120px);
    width: 100px;
    text-align: left;
}

.global {
    height: 100%;
}

/* .row {
    height: 100%;
} */

/* .rightcontent {
    background-color: #eff1f2;
}

.nav-tabs .nav-link{
    border: 0px #2e3338;
} */
/* .nav-tabs {
    border-bottom: 0px;
} */
/* div.leftnav.col-md-3.col-4{
    padding: 50px 0 0 0;
} */
.content {

    /* background-image: url(https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fhbimg.b0.upaiyun.com%2F9f8ddcca7f7622efeae192c8a2dfe890bb6aa4d313d2ff-UZ3yLl_fw658&refer=http%3A%2F%2Fhbimg.b0.upaiyun.com&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1642296017&t=ba34b4453c2d23a76d8f7204a8b792d9); */
    /* background-size: 100% 100%;
    background-repeat: no-repeat; */

}
/* h4 {
    margin-top: 40px;
} */