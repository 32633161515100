.main-footer {
    /*background-color: #000;*/
    color: white;
    /*padding-top: .4rem;*/
    /*padding-bottom: .4rem;*/
    height: 32px;
    position: relative;
    bottom: 0;
    width: 100%;
    text-align: center;
}

a:link {
    /*color: lightskyblue;*/
    background-color: transparent;
    text-decoration: none;
}

a:visited {
    color: white;
    background-color: transparent;
    text-decoration: none;
}

a:hover {
    color: blue;
    background-color: transparent;
    text-decoration: underline;
}

a:active {
    /*color: gray;*/
    color: white;
    background-color: transparent;
    text-decoration: underline;
}
