
.login {
    position: relative;
    width: 420px;
    height: 320px;
    margin: 10% auto auto 50%;
    padding: 20px;
    border-width: 1px;
    border-color: rgb(204, 198, 198);
    border-style: solid;
    background-color: white;
    border-radius: 5px;
    box-shadow: 5px 5px 5px rgb(59, 63, 63);;
    z-index: 1;
}

.bgimg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

