/*.App {*/
/*  height: calc(100vh - 32px);*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  animation: App-logo-spin infinite 20s linear;*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

/*@media (max-width: 991.98px) {*/
/*  .offcanvas-collapse {*/
/*    position: fixed;*/
/*    top: 56px; !* Height of navbar *!*/
/*    bottom: 0;*/
/*    left: 100%;*/
/*    width: 100%;*/
/*    padding-right: 1rem;*/
/*    padding-left: 1rem;*/
/*    overflow-y: auto;*/
/*    visibility: hidden;*/
/*    background-color: #343a40;*/
/*    transition: visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;*/
/*    transition: transform .3s ease-in-out, visibility .3s ease-in-out;*/
/*    transition: transform .3s ease-in-out, visibility .3s ease-in-out, -webkit-transform .3s ease-in-out;*/
/*  }*/

/*  .offcanvas-collapse.open {*/
/*    visibility: visible;*/
/*    -webkit-transform: translateX(-100%);*/
/*    transform: translateX(-100%);*/
/*    text-align: left;*/
/*  }*/
/*}*/


/*.nav-scroller {*/
/*  position: relative;*/
/*  z-index: 2;*/
/*  height: 2.75rem;*/
/*  overflow-y: hidden;*/
/*}*/

/*.nav-scroller .nav {*/
/*  display: -ms-flexbox;*/
/*  display: flex;*/
/*  -ms-flex-wrap: nowrap;*/
/*  flex-wrap: nowrap;*/
/*  padding-bottom: 1rem;*/
/*  margin-top: -1px;*/
/*  overflow-x: auto;*/
/*  color: rgba(255, 255, 255, .75);*/
/*  text-align: center;*/
/*  white-space: nowrap;*/
/*  -webkit-overflow-scrolling: touch;*/
/*}*/

/*.nav-underline .nav-link {*/
/*  padding-top: .75rem;*/
/*  padding-bottom: .75rem;*/
/*  font-size: .875rem;*/
/*  color: #6c757d;*/
/*}*/

/*.nav-underline .nav-link:hover {*/
/*  color: #007bff;*/
/*}*/

/*.nav-underline .active {*/
/*  font-weight: 500;*/
/*  color: #343a40;*/
/*}*/

/*.text-white-50 { color: rgba(255, 255, 255, .5); }*/

/*.bg-purple { background-color: #6f42c1; }*/

/*.lh-100 { line-height: 1; }*/
/*.lh-125 { line-height: 1.25; }*/
/*.lh-150 { line-height: 1.5; }*/


.App {
    height: calc(100vh - 32px);
    padding: 2em 0;
    text-align: center;
    background-color: #f4f4f4;
    box-sizing: border-box;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
}

.menu-logo {
    width: 100%;
    height: 115px;
}

.social-wrap {
    padding: 15px 0;
}

.social {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social li {
    margin: 0 10px;
}

.social img {
    width: 30px;
    height: auto;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 2em - 4px); /*parent padding 2em and 2px + 2px borders top and bottom*/
    max-width: 1400px;
    margin: 0 auto;
    border: 2px solid #eee;
    background-color: #fafafa;
    font-size: calc(10px + 2vmin);
}

.App-link {
    color: #333;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}


